var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"workspaces__adminpanel-users-list"},[(_vm.filterUsers.length)?_c('v-data-table',{attrs:{"headers":_vm.headersForRendering,"items":_vm.filterUsers,"elevation":0,"item-key":"id","mobile-breakpoint":0,"items-per-page":-1,"fixed-header":"","item-class":_vm.getUserRowClass,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getCorrectTypeRole(item.type).toLowerCase())+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(!_vm.isWorkspaceOwner(item.userInfo.id))?_c('v-menu',{attrs:{"min-width":"250px","offset-y":"","offset-x":"","nudge-right":"40","nudge-bottom":"10","close-on-content-click":_vm.isWorkspaceGuestList ? false : true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-icon',{attrs:{"medium":"","color":"black"}},[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,true)},[(_vm.isWorkspaceGuestList)?[_c('v-list',[_c('v-list-group',{staticClass:"px-0",scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-tooltip',{attrs:{"disabled":_vm.canAddMoreMembersToActiveWorkspace,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item-title',_vm._g({staticClass:"roles-header",class:{'list-item-disabled': !_vm.canAddMoreMembersToActiveWorkspace}},on),[_vm._v(" Invite as workspace member ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.NOT_PERMITTED))])])]},proxy:true}],null,true)},[(_vm.canAddMoreMembersToActiveWorkspace)?_c('v-list-group',{staticClass:"px-0 roles-item-header",attrs:{"no-action":"","sub-group":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item',{staticClass:"px-0 roles-item"},_vm._l((_vm.userRoles),function(role,i){return _c('div',{key:i,on:{"click":function($event){return _vm.inviteGuest(item, role)}}},[_vm._v(" "+_vm._s(role)+" ")])}),0)]},proxy:true}],null,true)}):_vm._e()],1)],1)]:_vm._e(),_c('v-card',{staticClass:"nav-card pt-0 disable-shadow"},[(_vm.isWorkspaceMemberList)?_vm._l((_vm.userRoles),function(role,i){return _c('p',{key:i,on:{"click":function($event){_vm.askAndPerform(_vm.updateWorkspaceMembership)({
                permissionType: role.toLowerCase(),
                workspaceId: _vm.activeWorkspaceId,
                sharingUserId: item.userInfo.id,
              })}}},[_vm._v(" "+_vm._s(role)+" ")])}):_vm._e(),_c('p',{class:{ 'workspaces__adminpanel-users-list-remove' : _vm.isWorkspaceMemberList },on:{"click":function($event){_vm.askAndPerform(_vm.deleteWorkspaceMembership)({
              member: {
                workspaceId: _vm.activeWorkspaceId,
                userId: item.userInfo.id,
              },
              type: _vm.listType,
            })}}},[_vm._v(" Remove "),(_vm.isWorkspaceGuestList)?_c('span',{staticClass:"ml-1"},[_vm._v("guest")]):_vm._e()])],2)],2):_vm._e()]}}],null,true)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }