<template>
  <div>
    <div v-if="isLoadingRowData">
      <v-skeleton-loader
        class="mb-10"
        type="heading" />
      <ScheduleSkeleton
        class="mb-10"
        :body-rows="3"
        :header-columns="4" />
    </div>
    <div v-else>
      <v-row
        no-gutters
        class="justify-space-between mb-10">
        <h2
          v-if="isWorkspaceMemberList"
          class="align-self-center">
          Members
        </h2>
        <h2 v-else>
          Guests
        </h2>
        <div
          v-if="isWorkspaceMemberList"
          class="d-flex">
          <div class="mr-4 align-self-center">
            {{ getUsersList.length }} / {{ getActiveWorkspaceLicencesAmount }} licenses
            used
          </div>
          <CollectionShareDialog
            :ws-guests="guestUsers"
            :members="formatMemberUsers"
            :dialog-type="TYPE_WORKSPACE_SHARE"
            :invite-to-text-type="WORKSPACE"
            :permission-types="getPermissionTypes"
            @updateSharedUser="updateWorkspaceMemberships($event)"
            @deleteSharedUser="deleteWorkspaceMemberships({
              member: $event,
              type: listType,
            })"
            @manageSharedUser="inviteToWorkspace" />
        </div>
      </v-row>
      <div
        v-if="!getUsersList.length"
        class="sub-font">
        There are no
        <span v-if="isWorkspaceGuestList">guests </span>
        <span v-if="isWorkspaceMemberList">members </span>
        yet
      </div>
      <v-row
        class="mb-15"
        no-gutters>
        <user-list
          :list-type="listType"
          :users="getUsersList" />
      </v-row>
    </div>
  </div>
</template>
<script>
import membersMixin from '@/mixins/membersMixin';
import {
  mapActions,
  mapGetters,
  mapState,
  mapMutations,
} from 'vuex';
import userList from '@/components/Workspace/WorkspaceAdminpanel/Members/userList';
import CollectionShareDialog from '@/components/CollectionsLibrarysRelocate/ShareDialog';
import ScheduleSkeleton from '@/components/App/AppSkeleton/ScheduleSkeleton';
import { WORKSPACE } from '@/constants/cores';
import { TYPE_WORKSPACE_SHARE } from '@/constants';
import { GET_PERMISSION_TYPES } from '@/constants/userPermissions';
export default {
  name: 'MembersRow',
  components: {
    userList,
    CollectionShareDialog,
    ScheduleSkeleton,
  },
  mixins: [membersMixin],
  data: () => ({
    TYPE_WORKSPACE_SHARE,
    WORKSPACE,
  }),
  computed: {
    ...mapState('Workspace', ['memberUsers', 'guestUsers', 'invitedUsersForShareWorkspace', 'activeWorkspaceId']),
    ...mapState(['isLoadingRowData']),
    ...mapGetters('Workspace', ['getActiveWorkspaceLicencesAmount', 'getActiveWorkspaceOwner', 'getCorrectTypeRole']),
    ...mapGetters('FeatureFlags', ['showWorkspaceAddMemberModal']),
    formatMemberUsers() {
      return this.memberUsers.map(member => ({
        type: this.getCorrectTypeRole(member.type),
        ...member.userInfo,
      }));
    },
    getUsersList () {
      if (this.isWorkspaceMemberList) {
        return this.memberUsers;
      }
      if (this.isWorkspaceGuestList) {
        return this.guestUsers;
      }
      return [];
    },
    getPermissionTypes() {
      return GET_PERMISSION_TYPES();
    },
  },
  created() {
    this.listUsersInWorkspace({
      type: this.listType,
    });
  },
  methods: {
    ...mapMutations(['spinner', 'openSnackBar']),
    ...mapActions(['handleError']),
    ...mapMutations('Workspace', ['updateWorkspacesList']),
    ...mapActions('Workspace', [
      'listAllUsersInWorkspace',
      'listUsersInWorkspace',
      'manageInvitedUsersForShareWorkspace',
      'updateWorkspaceMemberships',
      'deleteWorkspaceMemberships',
      'inviteToWorkspace',
    ]),
  },
};
</script>
<style scoped>
</style>
