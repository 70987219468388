<template>
  <div>
    <h2 class="mb-10">
      Settings
    </h2>
    <div class="mb-5">
      Workspace can be published as a community page now! This will consolidate
      all your Community Published Collections on one page for easier navigation
      in the Community area and let other users know whose collections are these.
      Publish your workspace and check it out on the Community tab.
    </div>
    <v-row
      class="justify-space-between"
      no-gutters>
      <div class="font-weight-bold">
        {{ getActiveWorkspaceName }} Page
      </div>
      <div>
        <v-tooltip
          :disabled="canPublishWs.allowed"
          bottom>
          <template #activator="{ on: onTooltip }">
            <v-menu
              :disabled="!canPublishWs.allowed"
              offset-y
              offset-x
              nudge-right="40"
              nudge-bottom="10"
              close-on-content-click>
              <template #activator="{ on: onMenu }">
                <div
                  v-on="{...onTooltip, ...onMenu }">
                  <v-icon
                    medium
                    color="black"
                    class="pointer">
                    mdi-dots-vertical
                  </v-icon>
                </div>
              </template>
              <v-card
                class="nav-card py-0 disable-shadow">
                <p
                  :style="{color: !canPublishWs.allowed ? 'gray' : ''}"
                  @click="manageCreationPage">
                  {{ isActiveWorkspacePublished ? 'Unp' : 'P' }}ublish
                </p>
              </v-card>
            </v-menu>
          </template>
          <span v-if="!canPublishWs.allowed">
            {{ GET_TEXT_REASON(canPublishWs.reason) }}
          </span>
        </v-tooltip>
      </div>
    </v-row>
  </div>
</template>
<script>
import { GET_TEXT_REASON } from '@/constants/userPermissions';
import {
  mapState, mapGetters, mapActions,
} from 'vuex';
export default {
  name: 'Settings',
  computed: {
    ...mapState('Workspace', ['activeWorkspaceId']),
    ...mapGetters('Workspace', ['getActiveWorkspace', 'isActiveWorkspaceAllowPages', 'isActiveWorkspacePublished', 'getActiveWorkspacePage']),
    ...mapGetters('UserRoles', ['canPublishWs']),
    ...mapGetters('FeatureFlags', ['showUpdatesToWorkspaceGuests']),
    getActiveWorkspaceName() {
      const { name = '' } = this.getActiveWorkspace ?? {
      };
      return name;
    },
  },
  methods: {
    ...mapActions('Workspace', ['deleteWorkspacePage', 'createWorkspacePage', 'listWorkspacePages']),
    ...mapActions('MyPages', ['followPage']),
    GET_TEXT_REASON,
    async manageCreationPage() {
      if (!this.isActiveWorkspaceAllowPages) {
        return;
      }
      const workspaceId = this.activeWorkspaceId;
      if (this.isActiveWorkspacePublished) {
        this.deleteWorkspacePage({
          workspaceId,
          pageId: workspaceId, // in future it will be wrong if we will have a lot of ws pages in one ws
        });
        return;
      }
      await this.createWorkspacePage({
        workspaceId,
      });

      if (this.showUpdatesToWorkspaceGuests) {
        this.followPage({
          pageId: workspaceId,
          pageWorkspaceId: workspaceId,
        });
      }
    },
  },
};
</script>
<style scoped lang="scss">
</style>
