import {
  TYPE_GUEST,
  TYPE_MEMBER,
} from '@/constants/userPermissions';
export default {
  props: {
    listType: {
      type: String,
      default: TYPE_MEMBER,
      validator(value) {
        return [TYPE_MEMBER, TYPE_GUEST].includes(value);
      },
    },
  },
  data: () => ({
    TYPE_GUEST,
    TYPE_MEMBER,
  }),
  computed: {
    isWorkspaceMemberList() {
      return this.listType == TYPE_MEMBER;
    },
    isWorkspaceGuestList() {
      return this.listType == TYPE_GUEST;
    },
  },
};
