<template>
  <div />
</template>
<script>
export default {
  name: 'Billing',
};
</script>
<style scoped>
</style>
