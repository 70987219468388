<template>
  <div class="workspaces__adminpanel-users-list">
    <v-data-table
      v-if="filterUsers.length"
      :headers="headersForRendering"
      :items="filterUsers"
      :elevation="0"
      item-key="id"
      :mobile-breakpoint="0"
      :items-per-page="-1"
      fixed-header
      :item-class="getUserRowClass"
      hide-default-footer>
      <template #[`item.type`]="{ item }">
        {{ getCorrectTypeRole(item.type).toLowerCase() }}
      </template>
      <template #[`item.action`]="{ item }">
        <v-menu
          v-if="!isWorkspaceOwner(item.userInfo.id)"
          min-width="250px"
          offset-y
          offset-x
          nudge-right="40"
          nudge-bottom="10"
          :close-on-content-click="isWorkspaceGuestList ? false : true">
          <template #activator="{ on }">
            <div
              v-on="on">
              <v-icon
                medium
                color="black">
                mdi-dots-vertical
              </v-icon>
            </div>
          </template>
          <template v-if="isWorkspaceGuestList">
            <v-list>
              <v-list-group class="px-0">
                <template v-slot:activator>
                  <v-tooltip
                    :disabled="canAddMoreMembersToActiveWorkspace"
                    bottom>
                    <template #activator="{ on }">
                      <v-list-item-title
                        class="roles-header"
                        :class="{'list-item-disabled': !canAddMoreMembersToActiveWorkspace}"
                        v-on="on">
                        Invite as workspace member
                      </v-list-item-title>
                    </template>
                    <span> {{ NOT_PERMITTED }}</span>
                  </v-tooltip>
                </template>
                <v-list-group
                  v-if="canAddMoreMembersToActiveWorkspace"
                  class="px-0 roles-item-header"
                  no-action
                  sub-group>
                  <template v-slot:activator>
                    <v-list-item class="px-0 roles-item">
                      <div
                        v-for="(role, i) in userRoles"
                        :key="i"
                        @click="inviteGuest(item, role)">
                        {{ role }}
                      </div>
                    </v-list-item>
                  </template>
                </v-list-group>
              </v-list-group>
            </v-list>
          </template>
          <v-card class="nav-card pt-0 disable-shadow">
            <template v-if="isWorkspaceMemberList">
              <p
                v-for="(role, i) in userRoles"
                :key="i"
                @click="askAndPerform(updateWorkspaceMembership)({
                  permissionType: role.toLowerCase(),
                  workspaceId: activeWorkspaceId,
                  sharingUserId: item.userInfo.id,
                })">
                {{ role }}
              </p>
            </template>
            <p
              :class="{ 'workspaces__adminpanel-users-list-remove' : isWorkspaceMemberList }"
              @click="askAndPerform(deleteWorkspaceMembership)({
                member: {
                  workspaceId: activeWorkspaceId,
                  userId: item.userInfo.id,
                },
                type: listType,
              })">
              Remove
              <span
                v-if="isWorkspaceGuestList"
                class="ml-1">guest</span>
            </p>
          </v-card>
        </v-menu>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import {
  TYPE_MEMBER,
  TYPE_GUEST,
  NOT_PERMITTED,
  GET_USER_ROLES,
} from '@/constants/userPermissions';
import membersMixin from '@/mixins/membersMixin';
import {
  mapActions,
  mapGetters,
  mapState,
} from 'vuex';
import { askAndPerform } from '@/utils';
import TableHeadersToMobile from '@/mixins/TableHeadersToMobile';
export default {
  name: 'UserList',
  mixins: [membersMixin, TableHeadersToMobile],
  props: {
    users: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    TYPE_MEMBER,
    TYPE_GUEST,
    NOT_PERMITTED,
    // for TableHeadersToMobile mixin
    MOBILE_COLS_TO_RENDERING: [
      'userInfo.email',
      'type',
      'action',
    ],
    userRoles: GET_USER_ROLES(),
  }),
  computed: {
    ...mapState('Workspace', ['activeWorkspaceId']),
    ...mapGetters('Workspace', ['isWorkspaceOwner', 'canAddMoreMembersToActiveWorkspace', 'getCorrectTypeRole']),
    filterUsers() {
      return this.users.filter(e => e.userInfo?.id);
    },
    headers() {
      const headers = [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'userInfo.name',
          width: '150',
        },
        {
          text: 'Email',
          align: 'start',
          sortable: false,
          value: 'userInfo.email',
        },
        {
          text: 'Role',
          align: 'start',
          sortable: false,
          value: 'type',
        },
        {
          text: '',
          value: 'action',
          sortable: false,
          align: 'right',
        },
      ];
      if (this.isWorkspaceGuestList) {
        return headers.filter(h => h.value != 'type');
      }
      return headers;
    },
  },
  methods: {
    ...mapActions('Workspace', ['updateWorkspaceMembership', 'deleteWorkspaceMembership']),
    getUserRowClass(item) {
      if (this.isWorkspaceOwner(item.userInfo?.id)) {
        return 'row-owner';
      }
      return '';
    },
    inviteGuest({ userInfo = null } = {
    }, role = '') {
      const { id: sharingUserId = '', name = '' } = userInfo ?? {
      };
      const { updateWorkspaceMembership, textReassignGuest, activeWorkspaceId: workspaceId } = this;
      this.askAndPerform(updateWorkspaceMembership, textReassignGuest(name))({
        permissionType: role.toLowerCase(),
        workspaceId,
        sharingUserId,
      });
    },
    textReassignGuest(username = '') {
      return `Invite ${username} Guest as a Workspace Member? This will give the User access to ALL OBJECTS in this Workspace with their assigned role.`;
    },
    askAndPerform,
  },
};
</script>
<style scoped lang="scss">
::v-deep .v-list-group__header {
  padding: 0 !important;
  font-size: 14px !important;
}
::v-deep .v-list-item.v-list-group__header {
  .roles-header {
    height: 29px !important;
    display: flex;
    align-items: center;
    padding-left: 26px !important;
    padding-right: 26px !important;
    &:hover {
      background: #F4F4F4;
    }
  }
}
::v-deep .v-list-group__header__append-icon {
  display: none !important;
}
::v-deep .v-list {
  &-item__title {
    font-size: 14px !important;
    line-height: 16px !important;
  }
}
::v-deep .v-list-group__header__prepend-icon {
  display: none !important
}
::v-deep .v-list-group__header__append-icon {
  display: none !important
}
::v-deep .v-list-item {
  display: block;
  height: initial !important;
  &:hover {
    color: initial !important;
  }
}
::v-deep .theme--light.v-list-item--link.v-list-item {
  &:hover {
    color: initial !important;
  }
  &:before {
    opacity: 0;
  }
}
::v-deep .v-menu__content .v-list-item {
  height: initial;
}
.roles-item {
  display: block;
  padding-right: 0 !important;
  padding-left: 0 !important;
  div {
    padding-left: 38px !important;
    height: 29px;
    display: flex;
    align-items: center;
  }
  div:hover {
    background: #F4F4F4;
  }
}
::v-deep .v-list-item--active {
  border-left: 0px !important;
}
::v-deep .v-list-item--active .roles-header {
  background: #F4F4F4;
}
.nav-card.disable-shadow {
  box-shadow: unset;
}
::v-deep .v-list-item__title.list-item-disabled {
  font-weight: initial !important;
}
::v-deep .v-data-table__wrapper {
  max-height: 520px;
}
</style>
