<template>
  <div>
    <v-row class="workspaces__adminpanel mb-10 justify-space-between">
      <v-col
        cols="12"
        sm="12"
        md="4"
        class="nav-card nav-card__account-menu pa-0 align-self-start"
        style="max-width: 250px">
        <template
          v-for="item in items">
          <router-link
            v-if="item.render"
            :key="item.title"
            :to="item.link"
            class="mt-auto mb-auto">
            <p
              class="small-p">
              <v-icon
                v-if="!item.isImg"
                color="lightBlack"
                style="font-size: 16px"
                class="mr-2">
                {{ item.icon }}
              </v-icon>
              <img
                v-else
                width="16px"
                height="16px"
                class="op-1 pointer workspaces__adminpanel-img"
                :src="item.icon"
                alt="">
              {{ item.title }}
            </p>
          </router-link>
        </template>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="8">
        <component
          :is="$route.params.field"
          v-if="checkArr.includes($route.params.field)" />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import Billing from '@/components/Workspace/WorkspaceAdminpanel/Billing';
import Settings from '@/components/Workspace/WorkspaceAdminpanel/Settings';
import Members from '@/components/Workspace/WorkspaceAdminpanel/Members';
export default {
  name: 'WorkspaceAdminpanel',
  components: {
    billing: Billing,
    settings: Settings,
    members: Members,
  },
  data: () => ({
    checkArr: [],
  }),
  computed: {
    ...mapState('Workspace', {
      workspaceId: 'activeWorkspaceId',
    }),
    items() {
      return [
        {
          title: 'Members',
          icon: 'mdi-account-group',
          isImg: false,
          param: 'members',
          link: {
            name: `workspace-adminpanel-members`,
            params: {
              field: 'members',
            },
          },
          openOnThisPage: true,
          render: true,
        },
        {
          title: 'Settings',
          icon: 'mdi-settings',
          isImg: false,
          param: 'settings',
          link: {
            name: `workspace-adminpanel-members`,
            params: {
              field: 'settings',
            },
          },
          openOnThisPage: true,
          render: true,
        },
        {
          title: 'Workspace Account',
          openOnThisPage: false,
          icon: '',
          isImg: false,
          link: {
            name: 'workspace-payment',
            params: {
              wId: this?.workspaceId,
            },
          },
          render: true,
        },
      ];
    },
  },
  created() {
    this.checkArr = this.items.filter(item => item.openOnThisPage && item?.param).map(item => item.param);
  },
};
</script>
<style scoped>
</style>
