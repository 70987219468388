<template>
  <div>
    <members-row
      v-for="wUser in workspaceUserTypes"
      :key="wUser"
      :list-type="wUser" />
  </div>
</template>
<script>
import membersRow from '@/components/Workspace/WorkspaceAdminpanel/Members/membersRow';
import { mapState } from 'vuex';
export default {
  name: 'Members',
  components: {
    membersRow,
  },
  data: () => ({
  }),
  computed: {
    ...mapState('Workspace', ['workspaceUserTypes']),
  },
};
</script>
<style scoped>
</style>
